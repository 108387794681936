<template>
  <div class="table-search">
    <h3>红人解约：</h3>
    <el-form ref="elForm" inline :model="formData" :rules="rules" label-width="120px">
      <el-form-item label="红人" prop="nickname">
        <el-autocomplete
            class="search-user"
            v-model="formData.nickname"
            :fetch-suggestions="searchUser"
            placeholder="输入红人昵称检索"
            @select="handleSelectQuery"
            @clear="clearSelected"
            clearable>
        </el-autocomplete>
      </el-form-item>
      <el-form-item label="解约类型" prop="fired_type">
        <el-select v-model="formData.fired_type" placeholder="请选择">
          <el-option
                  v-for="item in typeOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="解约时间" prop="fired_at">
        <el-date-picker
            v-model="formData.fired_at"
            type="date"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            placeholder="选择日期">
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm" v-if="userPermissions.indexOf('sign_fired_page') > -1">确认解约
        </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "SignFired",
  components: {  },
  props: [],
  data() {
    return {
      formData: {
        id: undefined,
        nickname: undefined,
        fired_at: undefined,
        fired_type:undefined,
      },
      typeOptions:[
        {value:'1',label:'到期解约'},
        {value:'2',label:'提前解约'},
      ],
      rules: {
        nickname: [{
          required: true,
          message: '请检索并选择转组红人',
          trigger: 'change'
        }],
        fired_type: [{
          required:true,
          message:'请选择解约类型',
          trigger:'change'
        }],
        fired_at: [{
          required: true,
          message: '请选择解约时间',
          trigger: 'change'
        }],
      }
    }
  },
  computed: {
    ...mapGetters(['userPermissions'])
  },
  watch: {},
  created() {
  },
  mounted() {
  },
  methods: {
    submitForm() {
      this.$refs['elForm'].validate(valid => {
        if (!valid) return
        // TODO 提交表单
        this.$confirm('此操作将[确认解约]操作, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.commitSave()
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消操作'
          });
        });


      })
    },
    async commitSave() {
      let info = await this.$api.signInfoFired({ id: this.formData.id, fired_at: this.formData.fired_at, fired_type:this.formData.fired_type })
      if (info === 'ok') {
        this.$notify.success("解约成功")
      }
    },
    resetForm() {
      this.$refs['elForm'].resetFields()
    },
    // 搜索用户,qs请求参数，cb回调函数，详见组件用法
    async searchUser(qs, cb) {
      let list = await this.$api.searchSignInfo(qs)
      cb(list)
    },
    // 处理搜索结果的选中
    handleSelectQuery(item) {
      this.formData.id = item.id
      this.formData.group_id = item.group_id
      this.formData.nickname = item.nickname
      if (this.formData.id) {
        this.$refs['elForm'].validateField('id')
      }
    },
    clearSelected() {
      this.formData.id = null
    },
    // 选择分组
    selectGroup(val) {
      this.formData.group_id = val
    },
  }
}

</script>
<style scoped>
</style>
